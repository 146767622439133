import React from "react";
import { withStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import autoCompleteStyle from "components/AutoComplete/autoCompleteStyle";
import AutoCompleteMultiple, {
  AutoCompleteMultipleI,
} from "./AutoCompleteMultiple";
import AutoCompleteSingle, { AutoCompleteSingleI } from "./AutoCompleteSingle";

type Suggestion = {
  label: string;
  secondaryLabel?: string;
};
export interface AutoCompleteSharedI {
  autoRenderSuggestions?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  fixedPositionWidth?: string;
  handleDelete?: (val: { label: string; value?: string }) => void;
  handleQuery?: (val: string) => void;
  label?: string;
  isAsync?: boolean;
  isLoading?: boolean;
  maxResults?: number;
  minChar?: number;
  placeholder?: string;
  onChange: (val: string) => void;
  suggestions: Suggestion[];
  isMulti?: boolean;
  dataAut?: string;
  classes?: Partial<
    Record<
      | "wrapper"
      | "root"
      | "container"
      | "label"
      | "errorMessage"
      | "paper"
      | "chip"
      | "inputRoot"
      | "inputRootDisabled"
      | "inputAutoWidth"
      | "inputMinWidth"
      | "divider"
      | "noResults",
      string
    >
  >;
}

export function inputWidthGenerator(inputProps) {
  let val = "";
  if (inputProps.value && inputProps.value.length) val = inputProps.value;
  else if (inputProps.placeholder && inputProps.placeholder.length)
    val = inputProps.placeholder;
  let result = 15;
  for (let i = 1; i < val.length; i++) {
    result += 12;
  }
  return `${result}px`;
}

export function getInternalInputProps(label, dataAut) {
  const result = {};
  if (label) result["aria-label"] = label;
  if (dataAut) result["data-aut"] = dataAut;
  return result;
}

// Converts objects to rendered text for dropdown menu
export function itemToString(item) {
  if (item && item.label) return item.label;
  else return "";
}

export function renderInput(inputProps) {
  const {
    InputProps,
    classes,
    disabled,
    noLeftPadding,
    useInputAutoWidth,
    ref,
    ...other
  } = inputProps;
  const style: { width: string; paddingLeft?: 0 } = {
    width: inputWidthGenerator(InputProps),
  };
  if (noLeftPadding) style.paddingLeft = 0;
  return (
    <TextField
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        disabled: disabled ? true : false,
        inputRef: ref,
        classes: {
          root: disabled ? classes.inputRootDisabled : classes.inputRoot,
          input: useInputAutoWidth
            ? classes.inputAutoWidth
            : classes.inputMinWidth,
        },
        ...InputProps,
        inputProps: {
          ...InputProps.inputProps,
          autoComplete: "off",
          style,
        },
      }}
      {...other}
    />
  );
}

export function renderSuggestions(
  suggestions,
  selectedItem,
  getItemProps,
  highlightedIndex,
  inputValue,
  classes,
  isLoading,
  minChar
) {
  if (
    isLoading ||
    (!suggestions.length &&
      inputValue &&
      inputValue.length &&
      inputValue.length >= minChar)
  ) {
    return (
      <MenuItem
        selected={false}
        component="div"
        className={classes.noResults}
        disabled
        style={{
          height: "auto",
          fontWeight: 400,
          display: "block",
        }}
      >
        <p style={{ margin: 0 }}>
          {isLoading ? "Loading Results" : "No Results Found"}
        </p>
      </MenuItem>
    );
  } else if (suggestions.length)
    return suggestions.map((suggestion, index) =>
      renderSuggestion({
        suggestion,
        index,
        itemProps: getItemProps({
          item: suggestion,
        }),
        highlightedIndex,
        selectedItem: selectedItem,
      })
    );
}

interface RenderSuggestionI {
  highlightedIndex: number;
  index: number;
  itemProps: any;
  selectedItem: { label: string };
  suggestion: { label: string; secondaryLabel?: string };
}
export function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}: RenderSuggestionI) {
  const isHighlighted = highlightedIndex === index;
  let isSelected;
  if (!selectedItem) isSelected = false;
  else if (Array.isArray(selectedItem))
    isSelected = selectedItem.some((item) => item.label === suggestion.label);
  else isSelected = (selectedItem.label || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={`${suggestion.label}${index}`}
      selected={isHighlighted}
      component="div"
      style={{
        height: "auto",
        fontWeight: isSelected ? 500 : 400,
        display: "block",
      }}
      data-aut={`Suggestions|${suggestion.label}`}
    >
      <p style={{ margin: 0 }}>{suggestion.label}</p>
      {suggestion.secondaryLabel && (
        <p style={{ margin: 0, fontSize: "11px" }}>
          {suggestion.secondaryLabel}
        </p>
      )}
    </MenuItem>
  );
}

export function getSuggestions(
  value,
  suggestions,
  minChar,
  autoRenderSuggestions,
  maxResults = 5,
  selectedItem,
  isAsync
) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  // If we have no input and autoRenderSuggestions is not toggled, do not render menu.
  if (inputLength === 0 && !autoRenderSuggestions) return [];
  // If we are async but we haven't typed the min characters for the search, do not show the menu
  if (isAsync && inputLength < minChar) return [];
  const result = [];
  // Count limits the amount of possible results
  let count = 0;
  for (let i = 0; i < suggestions.length; i++) {
    const suggestion = suggestions[i];
    // We don't need to filter async returns, as they've been filtered on the backend already
    if (
      isAsync ||
      validateSuggestion(suggestion, inputValue, inputLength, selectedItem)
    ) {
      result.push(suggestion);
      count++;
      // Limit the dropdown menu to only 5 items to prevent giant lists
      if (count >= maxResults) break;
    }
  }
  return result;
}

export function validateSuggestion(
  suggestion,
  input,
  inputLength,
  selectedItem
) {
  // See if the possible suggestion is already selected so that we don't render it
  if (selectedItem != null) {
    if (Array.isArray(selectedItem) && selectedItem.includes(suggestion))
      return false;
    else if (suggestion === selectedItem) return false;
  }
  // Initially search the string against the suggestion as a whole to capture spaces in input
  // i.e. Make sure 'USA Emp' returns true and honors the space
  if (suggestion.label.slice(0, inputLength).toLowerCase() === input)
    return true;
  if (suggestion.secondaryLabel?.slice(0, inputLength).toLowerCase() === input)
    return true;
  // Split the suggestion into words we can match against each one
  const splitSuggestion = suggestion.label.split(" ");
  const secondarySplitSuggestion = suggestion.secondaryLabel?.split(" ");
  // Loop through each word and see if it starts with our input
  for (let i = 0; i < splitSuggestion.length; i++) {
    if (splitSuggestion[i].slice(0, inputLength).toLowerCase() === input)
      return true;
  }
  if (secondarySplitSuggestion) {
    for (let i = 0; i < secondarySplitSuggestion.length; i++) {
      if (
        secondarySplitSuggestion[i].slice(0, inputLength).toLowerCase() ===
        input
      )
        return true;
    }
  }
  // If we looped through the array of suggestion characters with no match, return false
  return false;
}

const hasMulti = (value: unknown): value is AutoCompleteMultipleI => {
  return typeof value === "object" && value !== null && "isMulti" in value;
};

function IntegrationDownshift(
  props: AutoCompleteSingleI | AutoCompleteMultipleI
) {
  const classes = withStyles.getClasses(props);
  return hasMulti(props) ? (
    <AutoCompleteMultiple {...props} classes={classes} />
  ) : (
    <AutoCompleteSingle {...props} classes={classes} />
  );
}

const AutoCompleteStyled = withStyles(IntegrationDownshift, autoCompleteStyle);

export default AutoCompleteStyled;
